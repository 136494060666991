import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { HttpErrorResponse } from "@angular/common/http";

interface Errors {
  loading?: HttpErrorResponse | boolean;
  saving?: HttpErrorResponse | boolean;
  removing?: HttpErrorResponse | boolean;
}
export class ClearErrors {
  public static readonly type = "[Dashboard] clear errors";
  constructor(public payload?: {}) {}
}

export class SetError {
  public static readonly type = "[Dashboard] set errors";
  constructor(public error: Errors) {}
}

const clearAll: Errors = {
  loading: true,
  removing: true,
  saving: true
};

export class DashboardPageStateModel {
  loaded: boolean;
  loading: boolean;
  saving: boolean;
}

export const DashboardPagesStateDefaults = {
  items: [],
  loaded: false,
  loading: false,
  saving: false,
  errors: {
    loading: false,
    removing: false,
    saving: false
  }
};

export class DashboardStateModel {
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  errors: Errors;
}

@State<DashboardStateModel>({
  name: "dashboard",
  defaults: {
    loaded: false,
    loading: false,
    saving: false,
    errors: {
      loading: false,
      removing: false,
      saving: false
    }
  }
})
@Injectable()
export class DashboardState {
  constructor() {}

  @Selector()
  static errors(state: DashboardStateModel) {
    return state.errors;
  }

  @Action(SetError)
  setErrors(ctx: StateContext<DashboardStateModel>, { error }) {
    const state = ctx.getState();
    ctx.patchState({
      errors: {
        ...state.errors,
        loading: error.loading || state.errors.loading,
        saving: error.saving || state.errors.saving,
        removing: error.removing || state.errors.removing
      }
    });
  }

  @Action(ClearErrors)
  clearErrors(ctx: StateContext<DashboardStateModel>, { payload = clearAll }) {
    const state = ctx.getState();

    ctx.patchState({
      errors: {
        loading: payload.loading ? false : state.errors.loading,
        saving: payload.saving ? false : state.errors.saving,
        removing: payload.removing ? false : state.errors.removing
      }
    });
  }
}
